import { useDarkMode } from "../utils/DarkModeContext"
import { ChevronRightIcon } from "@heroicons/react/solid"
import { useState } from "react"
import GetStartedForm from "./GetStartedForm"
import { Link } from "react-router-dom"
import darkModePoster from "../assets/backup-dark.jpg"
import lightModePoster from "../assets/backup-light.jpg"

import Lottie from "react-lottie"
import animationData from "../assets/lottie/Tick.json"

export default function Hero() {
  const { isDarkMode } = useDarkMode()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // New state hooks for API response messages
  const [apiMessage, setApiMessage] = useState("")
  const [apiError, setApiError] = useState(false) // true if the API returns an error

  const defaultOptions = {
    loop: false,
    autoplay: true, // Consider setting this to false if you want to control playback manually
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const videoStyle = {
    position: "absolute",
    width: "100%",
    height: "100vh",
    objectFit: "cover",
    zindex: "10",
    // Adjust the filter as needed
    filter: `${isDarkMode ? "invert(100%)" : "none"}`,
    // Use a conditional operator to set the gradient color based on isDarkMode
    background: `linear-gradient(to top, ${
      isDarkMode
        ? "rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)"
        : "rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)"
    } 100%)`,
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    if (!isModalOpen) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }
  const [message, setMessage] = useState("")

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  }

  // Handle form submit
  // Example using Fetch API
  const handleSubmit = (event) => {
    event.preventDefault()

    // Collect form data
    const formData = {
      firstName: event.target["first-name"].value,
      lastName: event.target["last-name"].value,
      email: event.target.email.value,
      tel: event.target.tel.value,
      message: event.target.about.value,
    }

    fetch(
      "https://ivfw6a49o8.execute-api.eu-west-1.amazonaws.com/submit-form",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setApiMessage(data.message) // Update the message based on the API response
        if (data.success) {
          setFormSubmitted(true) // Update formSubmitted only if API indicates success
          setApiError(false)
        } else {
          // Handle API indicated errors without setting formSubmitted
          setApiError(true)
        }
      })
      .catch((error) => {
        console.error("Error:", error)
        setApiMessage("Failed to submit form due to a network error.") // Generic error message
        setApiError(true)
      })
  }

  return (
    <div className="isolate bg-white dark:bg-black p-6 lg:h-screen/2">
      {/* Background Video */}

      <div className="absolute inset-0 overflow-hidden z-0">
        <div className="absolute inset-0 overflow-hidden backdrop-blur-3xl z-10" />
        <video
          autoPlay
          loop
          muted
          playsInline
          style={videoStyle}
          poster={isDarkMode ? darkModePoster : lightModePoster}
          src="https://olenium-public-resources.s3.eu-west-1.amazonaws.com/GradientHero.mp4"
        ></video>
      </div>

      <div className="absolute inset-x-0 top-[-10rem] transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-[calc(50%-11rem)]h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#0085ff" />
              <stop offset={1} stopColor="#00b4ff" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative pb-20 sm:pb-24 absolute inset-x-0">
        <main className="mt-16 sm:mt-24">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-4">
              <div className="sm:text-center md:max-w-3xl md:mx-auto lg:col-span-6 lg:text-left">
                <div>
                  <div className="flex items-center justify-start gap-x-6 sm:justify-center lg:justify-start">
                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-900 ring-1 ring-gray-900 dark:ring-gray-300 dark:text-white dark:bg-black/10 backdrop-blur-3xl">
                      Based in Newcastle, United Kingdom
                    </div>
                  </div>
                  <h1 className="mt-4 text-5xl tracking-tight text-transparent font-extrabold sm:mt-5 sm:leading-none lg:mt-6 lg:text-7xl xl:text-7xl bg-clip-text bg-gradient-to-r from-gray-800 to-gray-900 dark:text-white">
                    We build beautiful sites for ambitious businesses
                  </h1>
                  <p className="mt-3 text-base text-black dark:text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-lg">
                    Based in Newcastle, United Kingdom, Olenium is dedicated to
                    creating exceptional digital experiences for ambitious
                    brands. Whether you need a sleek one-page site or a
                    comprehensive platform, we provide tailored solutions to
                    support and elevate your business.
                  </p>
                  <div className="flex items-left justify-start gap-x-6 mt-6 sm:justify-center lg:justify-start">
                    <a
                      href="https://calendar.app.google/SvtsyuUYwntV6Mqp7"
                      className="rounded-md bg-olenium-blue px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-olenium-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-olenium-blue"
                    >
                      Book a call
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6 lg:pl-8">
                <div
                  className={`sm:max-w-md sm:w-full sm:mx-auto rounded-3xl sm:overflow-hidden ${
                    isDarkMode ? "bg-gray-900" : "bg-white"
                  } shadow-2xl`}
                >
                  <div className="px-8 pt-2 pb-8 sm:px-8">
                    <div className="mt-8">
                      {!formSubmitted && (
                        <form onSubmit={handleSubmit}>
                          <p className="text-2xl font-bold text-black dark:text-white text-left text-balance">
                            Get in touch
                          </p>
                          <p className="mt-2 text-base text-black dark:text-white text-left">
                            We'd love to hear from you. Fill out the form below
                            and we'll get back to you as soon as possible.
                          </p>
                          {apiMessage && (
                            <div
                              className={`mt-4 p-4 rounded ${
                                apiError
                                  ? "bg-red-100 text-red-700"
                                  : "bg-green-100 text-green-700"
                              }`}
                            >
                              {apiMessage}
                            </div>
                          )}
                          <div className="space-y-6">
                            <div className="pb-6">
                              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                  >
                                    First Name
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="first-name"
                                      placeholder="First name"
                                      id="first-name"
                                      autoComplete="given-name"
                                      required
                                      className="block w-full rounded-md py-1.5 shadow-sm px-4 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6
                dark:text-white dark:bg-gray-800 dark:ring-gray-600 dark:placeholder:text-gray-400
                text-gray-900 bg-white ring-gray-300 placeholder:text-gray-400 focus:ring-transparent"
                                    />
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="last-name"
                                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                  >
                                    Last Name
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="last-name"
                                      placeholder="Last name"
                                      id="last-name"
                                      autoComplete="family-name"
                                      required
                                      className="block w-full rounded-md py-1.5 shadow-sm px-4 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6
                dark:text-white dark:bg-gray-800 dark:ring-gray-600 dark:placeholder:text-gray-400
                text-gray-900 bg-white ring-gray-300 placeholder:text-gray-400 focus:ring-transparent"
                                    />
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                  >
                                    Email Address
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      id="email"
                                      name="email"
                                      placeholder="Your email address"
                                      type="email"
                                      autoComplete="email"
                                      required
                                      className="block w-full rounded-md py-1.5 shadow-sm px-4 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6
                dark:text-white dark:bg-gray-800 dark:ring-gray-600 dark:placeholder:text-gray-400
                text-gray-900 bg-white ring-gray-300 placeholder:text-gray-400 focus:ring-transparent"
                                    />
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="tel"
                                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                  >
                                    Phone Number
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      id="tel"
                                      name="tel"
                                      placeholder="+44 1234 567890"
                                      type="tel"
                                      autoComplete="tel"
                                      className="block w-full rounded-md py-1.5 shadow-sm px-4 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6
                dark:text-white dark:bg-gray-800 dark:ring-gray-600 dark:placeholder:text-gray-400
                text-gray-900 bg-white ring-gray-300 placeholder:text-gray-400 focus:ring-transparent"
                                    />
                                  </div>
                                </div>

                                <div className="sm:col-span-6">
                                  <label
                                    htmlFor="about"
                                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                  >
                                    Message
                                  </label>
                                  <div className="mt-2">
                                    <textarea
                                      id="about"
                                      name="about"
                                      placeholder="Tell us about your project."
                                      rows="3"
                                      required
                                      className="block w-full rounded-md py-1.5 shadow-sm px-4 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6
                dark:text-white dark:bg-gray-800 dark:ring-gray-600 dark:placeholder:text-gray-400
                text-gray-900 bg-white ring-gray-300 placeholder:text-gray-400 focus:ring-transparent"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center justify-end gap-x-6">
                            <button
                              type="submit"
                              className="rounded-md bg-olenium-blue px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-olenium-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-olenium-blue"
                            >
                              Send
                            </button>
                          </div>
                        </form>
                      )}

                      {formSubmitted && (
                        <div className="isolate p-6 lg:h-screen/2">
                          <Lottie
                            options={defaultOptions}
                            height={150}
                            width={150}
                            isStopped={!formSubmitted} // Play animation when form is submitted
                          />
                          <p className="pt-8 text-base text-black dark:text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-lg text-center">
                            Thanks for getting in touch! We'll get back to you
                            as soon as possible.
                          </p>

                          <script>
                            {
                              window.lintrk("track", {
                                conversion_id: 15434500,
                              }) // Track the form submission
                            }
                          </script>

                          <div className="flex items-center justify-center gap-x-6 mt-6">
                            <a
                              href="https://calendar.app.google/SvtsyuUYwntV6Mqp7"
                              className="rounded-md bg-olenium-blue px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[#309CFF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-olenium-blue order-3"
                            >
                              Also want to book a call?
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="px-4 py-6 bg-white dark:bg-gray-500 border-t-2 border-gray-700 sm:px-10 rounded-b-xl">
                    <p className="text-xs leading-5 text-black dark:text-white">
                      By signing up, you agree to our{' '}
                      <a href="https://olenium-public-resources.s3.eu-west-1.amazonaws.com/Terms+of+Use.pdf" className="font-medium text-black dark:text-white hover:underline">
                        Terms of use{' '}
                      </a>
                      and{' '}
                      <a href="https://olenium-public-resources.s3.eu-west-1.amazonaws.com/Privacy+Policy.pdf" className="font-medium text-black dark:text-white hover:underline">
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {isModalOpen && (
        <GetStartedForm
          setShowModal={toggleModal}
          showModal={isModalOpen}
          message={message}
        />
      )}
    </div>
  )
}
